<template>
  <v-container fill-height class="align-start align-sm-center mt-10 mt-sm-0">
    <v-form id="loginForm" ref="loginForm" v-model="isValid" @submit.prevent="submit">
      <v-card class="elevation-12">
        <v-card-title class="primary">
          {{ $t('Login') }}
        </v-card-title>

        <v-card-text>
          <div class="flex">
            <div>
              <gli-text-field
                type="text"
                :label="$t('Username')"
                v-model="username"
                name="username"
                required
                :rules="[rules.required]"
                prepend-icon="person"
                autofocus
              ></gli-text-field>
              <gli-text-field
                :label="$t('Password')"
                v-model="password"
                name="password"
                required
                :rules="[rules.required]"
                :type="showPwd ? 'text' : 'password'"
                :append-icon="showPwd ? 'visibility_off' : 'visibility'"
                prepend-icon="lock"
                @click:append="showPwd = !showPwd"
              ></gli-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">{{ $t('Login') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<style lang="scss" scoped>
#loginForm {
  width: 375px;
  margin: auto;
}

.v-card > .v-card__title {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
import rules from '../mixins/rules';

export default {
  data() {
    return {
      isValid: false,
      username: '',
      password: '',
      showPwd: false,
      isLocaleChanging: false
    };
  },
  computed: {
    ...mapState('account', ['status'])
  },

  watch: {
    '$i18n.locale'() {
      this.isLocaleChanging = true;
      this.$nextTick(() => {
        this.isLocaleChanging = false;
      });
    }
  },
  methods: {
    ...mapActions('account', ['login']),

    submit() {
      if (this.$refs.loginForm.validate()) {
        this.showPwd = false;
        this.login({ username: this.username, password: this.password });
      }
    }
  },
  mixins: [rules]
};
</script>
